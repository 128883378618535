import React from 'react';
import { useCurrency } from '../../contexts/CurrencyContext';
import { useZone } from '../../contexts/ZoneContext';

const SelectCurrency = () => {
    const { currency, setCurrency } = useCurrency();
    const { zone } = useZone();

    return (
        <select className="mx-2 text-sm" value={currency} onChange={(e) => setCurrency(e.target.value)}>
            {zone === 'middle-east' && <option value="AED">AED</option>}
            {zone === 'middle-east' && <option value="SAR">SAR</option>}
            {zone === 'middle-east' && <option value="KWD">KWD</option>}
            {zone === 'middle-east' && <option value="QAR">QAR</option>}
            {zone === 'europe' && <option value="EUR">EUR</option>}
            {zone === 'europe' && <option value="USD">USD</option>}

        </select>
    )
}

export default SelectCurrency