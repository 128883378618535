import React, { useContext, useEffect, useState } from "react";
import {
    Button,
    Dialog,
    Card,
    CardBody,
    CardFooter,
    Typography,
    Spinner,
    Checkbox,
    Radio,
} from "@material-tailwind/react";

import { API_LINK } from "../../../apiConfig";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";

export const PaymentOrder = ({ open, handleOpen, setOrders, selectedOrder, setSelectedOrder, zones }) => {
    const { authTokens } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    const [orderPaymentStatus, setOrderPaymentStatus] = useState();
    const [zone, setZone] = useState('');

    useEffect(() => {
        if (open) {
            if (selectedOrder) {
                const zone = zones.filter(z => selectedOrder?.shippingCountry === z.country);
                setZone(zone[0].zone);
            }
        }
    }, [open, selectedOrder]);

    useEffect(() => {
        if (open) {
            if (selectedOrder) {
                setOrderPaymentStatus(selectedOrder?.paymentStatus || '');
            }
        }
    }, [open, selectedOrder]);

    const updatePaymentHandler = () => {
        setLoading(true);

        const requestConfig = {
            headers: {
                Authorization: `Bearer ${authTokens?.accessToken}`
            }
        };

        if (selectedOrder && zone) {
            axios.patch(`${API_LINK}/orders/${selectedOrder?.id}/payment-status?zone=${zone}`, requestConfig).then(({ data }) => {
                setOrders((orders) => orders.map(order => order.id === selectedOrder?.id ? { ...selectedOrder, paymentStatus: 'paid' } : order));
                closeModalHandler()
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setLoading(false);
            });
        } else {
            alert("la zone n'est pas indiquée !")
        }
    }


    const closeModalHandler = () => {
        handleOpen();
        setSelectedOrder(null);
        setOrderPaymentStatus('')
    }

    return (
        <>
            <Dialog
                size="sm"
                open={open}
                handler={closeModalHandler}
                className="bg-transparent shadow-none"
            >
                {!loading ? (<Card className="mx-auto w-full">
                    <CardBody className="flex flex-col gap-4">
                        <Typography variant="h4" color="blue-gray">
                            Mettre à jour le paiement d'une commande
                        </Typography>
                        <div className="w-full flex justify-center flex-wrap">
                            {/* <Checkbox
                                label="En traitement"
                                color="purple"
                                checked={orderPaymentStatus === 'paid'}
                                onChange={() => setOrderPaymentStatus('processing')}
                            /> */}







                            <div className="flex gap-10">
                                <Typography variant="lead" className="text-center">
                                    Voulez-vous vraiment confirmer le paiement de cette commande
                                </Typography>
                            </div>



                        </div>
                    </CardBody>
                    <CardFooter className="flex items-center gap-4 pt-0">
                        <Button variant="outlined" className="shadow-none rounded-none" onClick={() => closeModalHandler()} fullWidth>
                            Annuler
                        </Button>
                        <Button variant="filled" className="shadow-none bg-primary rounded-none" onClick={() => updatePaymentHandler()} fullWidth>
                            Mettre à jour
                        </Button>
                    </CardFooter>
                </Card>
                ) : (
                    <div className="flex justify-center items-center p-5">
                        <Spinner />
                    </div>
                )}
            </Dialog>
        </>
    );
}