import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { useCurrency } from '../contexts/CurrencyContext';
import fx from 'money';
import { BsPlus, BsBagPlus } from "react-icons/bs";
import { CartContext } from "../contexts/CartContext";
import { API_LINK } from "../apiConfig";
import { SidebarContext } from "../contexts/SidebarContext";
import { useZone } from "../contexts/ZoneContext";

const Product = ({ product }) => {
  const { t, i18n } = useTranslation();
  const { zone } = useZone();
  const { currency, rates } = useCurrency();
  const { addToCart } = useContext(CartContext);
  const { setIsOpen } = useContext(SidebarContext);
  const [hovered, setHovered] = useState(false);
  const [convertedPrice, setConvertedPrice] = useState(product.price);
  const [convertedPromotion, setConvertedPromotion] = useState(product.promotion);

  // Set the base currency


  // Update rates when they change
  useEffect(() => {

    fx.base = zone === "europe" ? "EUR" : zone === "middle-east" && "AED";


    // if (Object.keys(rates).length !== 0) {
    //   fx.rates = rates;
    //   // Convert prices on currency change
    //   if (currency !== 'AED') {
    //     setConvertedPrice(fx.convert(product.price, { from: fx.base, to: currency }));
    //     if (product.promotion) {
    //       setConvertedPromotion(fx.convert(product.promotion, { from: fx.base, to: currency }));
    //     }
    //   } else {
    //     setConvertedPrice(product.price);
    //     setConvertedPromotion(product.promotion);
    //   }
    // }


    if (Object.keys(rates).length !== 0 && zone && currency && product) {
      fx.rates = rates;

      // Convert prices on currency change
      if (currency !== fx.base) {
        // Utilisation des prix en fonction de la zone
        const priceToConvert = zone === "europe" ? product.priceEuro : product.price;
        const promotionToConvert = zone === "europe" ? product.promotionEuro : product.promotion;

        setConvertedPrice(fx.convert(priceToConvert, { from: fx.base, to: currency }));
        if (promotionToConvert) {
          setConvertedPromotion(fx.convert(promotionToConvert, { from: fx.base, to: currency }));
        }
      } else {
        // Si la devise est la même que la devise de base, utiliser les prix locaux
        const priceToUse = zone === "europe" ? product.priceEuro : product.price;
        const promotionToUse = zone === "europe" ? product.promotionEuro : product.promotion;

        setConvertedPrice(priceToUse);
        setConvertedPromotion(promotionToUse);
      }
    }

  }, [currency, rates, product, zone]);

  const handleAddToCart = (event) => {
    event.preventDefault();
    addToCart(product, product.id, 1);
    setIsOpen(true);
  };

  return (
    <Link to={`/product/${product.id}`}>
      <div
        className="border border-[#e4e4e4] mb-4 relative overflow-hidden group transition hover:shadow-xl hover:shadow-gray-100 bg-gradient-to-tr from-[#E6D9CF] via-[#F8F5F3] to-[#F8F5F3]"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <div className="w-full h-full flex justify-center items-center">
          <div className="mx-auto flex justify-center items-center">
            <img
              className="max-w-full group-hover:scale-110 transition duration-300"
              src={`${API_LINK}/${hovered ? product.images[1] : product.images[0]}`}
              alt={product.name}
            />
          </div>
        </div>
        <div className="absolute top-6 -right-11 group-hover:right-5 p-2 flex flex-col justify-center items-center gap-y-2 opacity-0 group-hover:opacity-100 transition-all duration-300">
          <button onClick={handleAddToCart}>
            <div className="flex justify-center items-center text-white w-12 h-12 bg-primary">
              <BsPlus className="text-3xl" />
            </div>
          </button>
        </div>
      </div>
      <div>
        <div className="text-sm capitalize text-gray-500 mb-0">{product.category === "diffuseur" ? t('diffusers') : product.category === "parfum" ? t('perfumes') : product.category === "candle-reed" && t('candles&reed')}</div>
        <Link to={`/product/${product.id}`}>
          <h2 className="font-semibold capitalize mb-0">{product.name}{product.specification && ` - ${product.specification}`}</h2>
        </Link>

        <div className="flex justify-between items-center flex-wrap">
          <div dir='ltr'>
            {product.promotion ? (
              <div className="flex items-center flex-wrap">
                <h2 className='font-normal mr-2'>
                  {convertedPromotion?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} {currency}
                </h2>
                <h2 className="font-normal text-red-500 line-through">
                  {convertedPrice?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                  <span className="font-normal"> {currency}</span>
                </h2>
              </div>
            ) : (
              <h2 className="font-normal">
                {convertedPrice?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                <span className="font-normal"> {currency}</span>
              </h2>
            )}
            {product.stockQuantity === 0 && <h2 className="text-red-400">Rupture de stock</h2>}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Product;

