import React, { useContext, useEffect, useState } from "react";
import {
    Button,
    Dialog,
    Card,
    CardBody,
    CardFooter,
    Typography,
    Spinner,
    Select,
    Option,
} from "@material-tailwind/react";
import { t } from "i18next";

import zoneWeb from '../../../img/zones/web.jpg';
import zoneMobile from '../../../img/zones/mobile.jpg';

export const SelectZone = ({ open, handleOpen, setZone, zones, zone }) => {

    const [selectedZone, setSelectedZone] = useState(zone)
    const uniqueZones = [...new Set(zones.map(zoneObj => zoneObj.zone))].map(zone => ({ zone }));

    const selectZoneHandler = () => {
        if (selectedZone) {
            setZone(selectedZone);
            closeModalHandler();
        }
    }

    const [web, setWeb] = useState(window.innerWidth >= 768);

    useEffect(() => {
        const handleResize = () => {
            setWeb(window.innerWidth >= 768);
        };

        window.addEventListener("resize", handleResize);

        // Cleanup on unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);


    const closeModalHandler = () => {
        handleOpen();
    }

    return (
        <>
            <Dialog
                size="sm"
                open={open}
                // handler={closeModalHandler}
                className="bg-transparent shadow-none"
            >
                <Card className="mx-auto w-full">
                    <CardBody className="flex flex-col gap-4">
                        <Typography variant="h4" color="blue-gray">
                            {t('select-area')}
                        </Typography>
                        <div className="w-full flex flex-col justify-center">
                            <img src={web ? zoneWeb : zoneMobile} alt="zones" className="mb-4" />
                            <Select
                                label={t('geographic-area')}
                                value={selectedZone}
                                onChange={(e) => setSelectedZone(e)}
                            >
                                {uniqueZones.map((zone) => <Option key={zone.zone} value={zone.zone} className="capitalize">{zone.zone === "europe" ? "Europe, Canada" : zone.zone}</Option>)}
                            </Select>
                        </div>
                    </CardBody>
                    <CardFooter className="pt-0">
                        <Button variant="filled" className="bg-primary text-white shadow-none rounded-none" onClick={() => selectZoneHandler()} fullWidth>
                            {t('select')}
                        </Button>
                    </CardFooter>
                </Card>
            </Dialog>
        </>
    );
}