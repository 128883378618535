import axios from 'axios';
import React, { createContext, useState, useContext, useEffect } from 'react';
import { useZone } from './ZoneContext';

const CurrencyContext = createContext();

export const CurrencyProvider = ({ children }) => {
    const [rates, setRates] = useState({});
    const [currency, setCurrency] = useState('AED');
    const { zone } = useZone();

    useEffect(() => {
        const loadRates = async () => {
            
            try {
                if (currency !== 'AED' && zone === 'middle-east' && Object.keys(rates).length === 0) {                    
                    const response = await axios.get('https://v6.exchangerate-api.com/v6/140ad4746e1865254b5bd0d9/latest/AED');
                    setRates(response.data.conversion_rates);
                }

                if (currency !== 'EUR' && zone === 'europe' && Object.keys(rates).length === 0) {                    
                    const response = await axios.get('https://v6.exchangerate-api.com/v6/140ad4746e1865254b5bd0d9/latest/EUR');
                    setRates(response.data.conversion_rates);                    
                }
            } catch (error) {
                console.error("Error fetching currency rates:", error);
            }
        };

        loadRates();
    }, [currency, zone]);

    return (
        <CurrencyContext.Provider value={{ rates, currency, setCurrency }}>
            {children}
        </CurrencyContext.Provider>
    );
};

export const useCurrency = () => {
    return useContext(CurrencyContext);
};
