import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import './i18n/i18n';
import { ThemeProvider } from "@material-tailwind/react";

import SidebarProvider from "./contexts/SidebarContext";
import CartProvider from "./contexts/CartContext";
import { AuthProvider } from "./contexts/AuthContext";
import { BrowserRouter } from "react-router-dom";
import { MaterialTailwindControllerProvider } from "./contexts/MaterialContext";
import { LoadingProvider } from './contexts/LoadingContext';
import { CurrencyProvider } from "./contexts/CurrencyContext";
import { ZoneProvider } from "./contexts/ZoneContext";



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <AuthProvider>
      <ZoneProvider>
        <SidebarProvider>
          <CartProvider>
            {/* <React.StrictMode> */}
            <ThemeProvider>
              <MaterialTailwindControllerProvider>
                <LoadingProvider>
                  <CurrencyProvider>
                    <App />
                  </CurrencyProvider>
                </LoadingProvider>
              </MaterialTailwindControllerProvider>
            </ThemeProvider>
            {/* </React.StrictMode> */}
          </CartProvider>
        </SidebarProvider>
      </ZoneProvider>
    </AuthProvider>
  </BrowserRouter>
);
