import React, { useContext, useEffect, useState } from "react";
import {
    Button,
    Dialog,
    Card,
    CardBody,
    CardFooter,
    Typography,
    Input,
    // Textarea,
    Select,
    Option,
    Spinner,
    IconButton,
    Switch,
} from "@material-tailwind/react";
import ReactQuill from 'react-quill';
import SelectMulti from 'react-select'


import 'react-quill/dist/quill.snow.css';
import "react-quill/dist/quill.core.css";

import ImageUploading from 'react-images-uploading';

import { PhotoIcon } from "@heroicons/react/24/outline";

import { API_LINK } from "../../../apiConfig";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { BsPencil, BsTrash, BsX } from "react-icons/bs";
import { useZone } from "../../../contexts/ZoneContext";

export const AddUpdateProduct = ({ open, handleOpen, setProducts, selectedProduct, setSelectedProduct }) => {
    const { authTokens } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    const { zones } = useZone();
    const [listZones, setListZones] = useState([]);

    useEffect(() => {
        const zoneOptions = zones.map(zone => ({
            value: zone.zone,
            label: zone.zone
        }));
        setListZones(zoneOptions);  // Set the zones in the state
    }, [zones]);

    const [name, setName] = useState('');
    const [sku, setSku] = useState('');
    const [descriptionFr, setDescriptionFr] = useState('');
    const [descriptionEn, setDescriptionEn] = useState('');
    const [stockQuantity, setStockQuantity] = useState('');
    const [category, setCategory] = useState('');

    const [price, setPrice] = useState('');
    const [promotion, setPromotion] = useState('');

    const [priceEuro, setPriceEuro] = useState('');
    const [promotionEuro, setPromotionEuro] = useState('');

    const [type, setType] = useState('');
    const [specification, setSpecification] = useState('');
    const [color, setColor] = useState('');

    const [shown, setShown] = useState(true);

    const [zoneProduct, setZoneProduct] = useState([]);

    const [images, setImages] = useState([]);
    const [video, setVideo] = useState('');

    const handleChange = (selectedOptions) => {
        // Extract only the 'value' from the selected options
        const selectedValues = selectedOptions.map(option => option.value);
        setZoneProduct(selectedValues);  // Update state with the values only
    };


    const generateSku = (category, name) => {
        const categoryPrefix = category ? category.toLowerCase().replace(/\s+/g, '') : null;
        const namePrefix = name ? name.toLowerCase().replace(/\s+/g, '') : null;
        return categoryPrefix && namePrefix ? `${categoryPrefix}-${namePrefix}` : '';
    };


    useEffect(() => {
        setLoading(true);
        if (open) {
            setLoading(false);

            if (selectedProduct) {

                setName(selectedProduct.name || '');
                setSku(selectedProduct.sku || '');
                setDescriptionFr(selectedProduct.descriptionFr || '');
                setDescriptionEn(selectedProduct.descriptionEn || '');
                setStockQuantity(selectedProduct.stockQuantity || '');
                setPrice(selectedProduct.price || '');
                setPromotion(selectedProduct.promotion || '');

                setPriceEuro(selectedProduct.priceEuro || '');
                setPromotionEuro(selectedProduct.promotionEuro || '');

                setCategory(selectedProduct.category || '');
                setType(selectedProduct.type || '');
                setSpecification(selectedProduct.specification || '');
                setColor(selectedProduct.color || '');
                setShown(selectedProduct.shown || false);
                setZoneProduct(selectedProduct.zones || [])
                // Séparer la chaîne des zones en un tableau
                // const zonesArray = selectedProduct?.zones ? selectedProduct?.zones[0].split(',') : [];
                // setZoneProduct(zonesArray);
                setVideo(selectedProduct.video || '');

                // setImages(selectedProduct.images || []);

                // Format the images array to match the format expected by ImageUploading component
                const formattedImages = selectedProduct.images.map((image, index) => ({
                    file: `${API_LINK}/${image}`, // Assuming that the image URLs are stored in 'images' array
                    key: index, // Add a unique key for each image
                    data_url: `${API_LINK}/${image}`, // Assuming that the image URLs are stored in 'images' array
                }));

                setImages(formattedImages);
            }
        }
    }, [open, selectedProduct]);


    useEffect(() => {
        if (category && !selectedProduct) {
            setSku(generateSku(category, name)); // Ne pas réinitialiser si un produit est sélectionné
        }
    }, [category, name]);


    const handleSkuChange = (e) => {
        const userInput = e.target.value.trim();
        const generatedSku = generateSku(category, name);  // Générer la base du SKU (category-name)

        // Vérifie si l'utilisateur a modifié la base 'category-name'
        if (!userInput.startsWith(generatedSku)) {
            // Réinitialise le SKU à 'category-name' si l'utilisateur a supprimé ou modifié cette partie
            setSku(generatedSku);
        } else {
            // Si l'utilisateur ajoute quelque chose à la base 'category-name', on garde la partie générée et ajoute le suffixe
            // Si le suffixe est vide, on n'ajoute pas de tiret

            const suffix = userInput.substring(generatedSku.length);
            if (suffix && !suffix.startsWith('-')) {
                // Ajouter un tiret si nécessaire
                setSku(generatedSku + '-' + suffix);
            } else {
                // Laisser l'utilisateur entrer sa propre valeur après la base
                setSku(userInput);
            }
        }
    };



    const addProductHandler = () => {
        setLoading(true);
        const formData = new FormData();
        if (name && sku && zoneProduct.length > 0 && descriptionFr && descriptionEn && stockQuantity && price && category && type && images.length > 0) {
            formData.append('name', name);
            formData.append('sku', sku);
            formData.append('descriptionFr', descriptionFr);
            formData.append('descriptionEn', descriptionEn);
            formData.append('price', price);
            formData.append('promotion', promotion);
            formData.append('priceEuro', priceEuro);
            formData.append('promotionEuro', promotionEuro);
            formData.append('stockQuantity', stockQuantity);
            formData.append('category', category);
            formData.append('type', type);
            formData.append('specification', specification);
            formData.append('color', color);
            formData.append('shown', shown);
            formData.append('zones', zoneProduct);
            formData.append('video', video);

            images.forEach((image) => {
                formData.append(`images`, image.file);
            });


            const requestConfig = {
                headers: {
                    Authorization: `Bearer ${authTokens?.accessToken}`
                }
            };

            if (selectedProduct) {
                axios.patch(`${API_LINK}/products/${selectedProduct?.id}`, formData, requestConfig).then(({ data }) => {
                    setProducts(products => products.map(product => product.id === data.id ? data : product));
                    closeModalHandler();
                    alert('Votre produit a bien été modifié')
                }).catch((error) => {
                    console.log(error);
                }).finally(() => {
                    setLoading(false);
                });
            } else {
                axios.post(`${API_LINK}/products`, formData, requestConfig).then(({ data }) => {
                    setProducts(products => [data, ...products]);
                    // closeModalHandler();
                    alert('Votre produit a bien été ajouté')
                }).catch((error) => {
                    console.log(error);
                }).finally(() => {
                    setLoading(false);
                });
            }
        } else {
            alert("Veuillez remplir tous les champs et ajouter au moins une image.");
        }
    }


    const closeModalHandler = () => {
        handleOpen();
        setName('');
        setSku('');
        setDescriptionFr('');
        setDescriptionEn('');
        setImages([]);
        setPrice('');
        setPromotion('');
        setPriceEuro('');
        setPromotionEuro('');
        setStockQuantity('');
        setCategory('');
        setType('');
        setVideo('');
        setShown(false);
        setZoneProduct([]);
        setSpecification('');
        setColor('');
        setSelectedProduct(null);
    }


    const onChange = (imageList, addUpdateIndex) => {
        setImages(imageList);
    };


    return (
        <>
            <Dialog
                size="xxl"
                open={open}
                // handler={closeModalHandler}
                className="bg-transparent shadow-none"
            >
                {!loading ? (<Card className="mx-auto w-full">
                    <CardBody className="flex flex-col gap-4 h-full">
                        <div className="flex items-center justify-between">
                            <Typography variant="h4" color="blue-gray">
                                {selectedProduct ? "Modifier le produit" : "Ajouter un produit"}
                            </Typography>
                            <div className="mx-5">
                                <Switch color="green" checked={shown} onChange={(e) => setShown(e.target.checked)} />
                            </div>
                            <div className="absolute top-0 right-0">
                                <IconButton onClick={() => { closeModalHandler() }} variant="text" className="rounded-none">
                                    <BsX size={32} />
                                </IconButton>
                            </div>
                        </div>

                        <div className="grid md:grid-cols-3 grid-cols-1 gap-4 w-full">
                            <ImageUploading
                                multiple
                                value={images}
                                onChange={onChange}
                                maxNumber={10}
                                dataURLKey="data_url"
                            >
                                {({
                                    imageList,
                                    onImageUpload,
                                    onImageRemoveAll,
                                    onImageUpdate,
                                    onImageRemove,
                                    dragProps,
                                }) => (
                                    <div>
                                        {imageList.length !== 0 ?
                                            <Button
                                                fullWidth
                                                size="md"
                                                className="mb-2 bg-primary shadow-none rounded-none"
                                                onClick={onImageUpload}
                                                {...dragProps}
                                            >
                                                Rajouter d'autres images
                                            </Button> :
                                            <label className="flex rounded-lg border-4 border-dashed w-full p-10 group text-center">
                                                <div className="h-full w-full text-center flex justify-center items-center">
                                                    <PhotoIcon className="h-[120px] text-gray-200" />
                                                </div>
                                                <button className="hidden" onClick={onImageUpload} {...dragProps}></button>
                                            </label>

                                        }
                                        <div className="max-h-64 overflow-y-auto">
                                            <div className="flex flex-wrap gap-4">
                                                {imageList.map((image, index) => (
                                                    <div key={index} className="image-item">
                                                        <img src={image['data_url']} alt="" width="100" />
                                                        <div className="flex justify-center items-center gap-2 p-2">
                                                            <button className="p-2 flex justify-center items-center text-white bg-primary rounded-none"
                                                                onClick={() => onImageUpdate(index)}
                                                            >
                                                                <BsPencil className="text-base" />
                                                            </button>

                                                            <button
                                                                className="p-2 bg-red-400 flex justify-center items-center text-white rounded-none"
                                                                onClick={() => onImageRemove(index)}
                                                            >
                                                                <BsTrash className="text-base" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        {imageList.length !== 0 && <Button onClick={onImageRemoveAll} fullWidth variant="filled" className="bg-red-400 shadow-none rounded-none mt-2">Supprimer toutes les images</Button>}
                                    </div>
                                )}
                            </ImageUploading>



                            <div className="sm:col-span-2 col-span-1">
                                {/* max-h-96  overflow-y-auto */}
                                <div className="grid sm:grid-cols-2 grid-cols-1 gap-4">
                                    <Input label="Nom du produit" type="text" value={name} onChange={(e) => setName(e.target.value)} />
                                    <Input label="Référence" type="text" value={sku} onChange={handleSkuChange} />

                                    <Select label="Catégorie" value={category} onChange={(e) => setCategory(e)}>
                                        <Option value="diffuseur">Diffuseur</Option>
                                        <Option value="parfum">Parfum</Option>
                                        <Option value="candle-reed">Candles & Reed</Option>
                                    </Select>

                                    {category === "diffuseur" && <Input label="Couleur" type="text" value={color} onChange={(e) => setColor(e.target.value)} />}

                                    {category === "diffuseur" && (
                                        <Select label="Type" value={type} onChange={(e) => setType(e)}>
                                            <Option value="business">Business</Option>
                                            <Option value="home">Home</Option>
                                        </Select>
                                    )}

                                    {category === "parfum" && (
                                        <Select label="Type" value={type} onChange={(e) => setType(e)}>
                                            <Option value="oriental">Oriental</Option>
                                            <Option value="fresh">Fresh</Option>
                                            <Option value="floral">Floral</Option>
                                        </Select>
                                    )}

                                    {category === "candle-reed" && (
                                        <Select label="Type" value={type} onChange={(e) => setType(e)}>
                                            <Option value="type1">Type 1</Option>
                                            <Option value="type2">Type 2</Option>
                                            <Option value="type3">Type 3</Option>
                                        </Select>
                                    )}

                                    {(category === "diffuseur" && type === "home") && (
                                        <Select label="Spécification" value={specification} onChange={(e) => setSpecification(e)}>
                                            <Option value="home">Home</Option>
                                            <Option value="voiture">Voiture</Option>
                                            <Option value="sanitaire">Sanitaire</Option>
                                        </Select>
                                    )}

                                    {(category === "diffuseur" && type === "business") && (
                                        <Select label="Spécification" value={specification} onChange={(e) => setSpecification(e)}>
                                            <Option value="200ml">Jusqu' à 200 ml</Option>
                                            <Option value="300ml">Jusqu' à 300 ml</Option>
                                            <Option value="400ml">Jusqu' à 400 ml</Option>
                                        </Select>
                                    )}


                                    {(category === "parfum") && (
                                        <Select label="Spécification" value={specification} onChange={(e) => setSpecification(e)}>
                                            <Option value="50ml">Jusqu' à 50 ml</Option>
                                            <Option value="100ml">Jusqu' à 100 ml</Option>
                                            <Option value="250ml">Jusqu' à 250 ml</Option>
                                            <Option value="400ml">Jusqu' à 400 ml</Option>
                                        </Select>
                                    )}

                                    <Input label="Quantité en stock" type="number" min={0} value={stockQuantity} onChange={(e) => setStockQuantity(e.target.value)} />
                                    <Input label="Prix" type="number" min={0} value={price} onChange={(e) => setPrice(e.target.value)} />
                                    <Input label="Promotion" type="number" min={0} value={promotion} onChange={(e) => setPromotion(e.target.value)} />

                                    <Input label="Prix Euro" type="number" min={0} value={priceEuro} onChange={(e) => setPriceEuro(e.target.value)} />
                                    <Input label="Promotion Euro" type="number" min={0} value={promotionEuro} onChange={(e) => setPromotionEuro(e.target.value)} />

                                    {/* <div className="sm:col-span-2 col-span-1">
                                        <ReactQuill theme="snow" className="view ql-editor" value={descriptionFr} onChange={setDescriptionFr} />
                                    </div> */}

                                    <div className="sm:col-span-2 col-span-1">
                                        <ReactQuill theme="snow" className="view" placeholder="Description en français" value={descriptionFr} onChange={setDescriptionFr} />
                                    </div>

                                    <div className="sm:col-span-2 col-span-1">
                                        <ReactQuill theme="snow" className="view" placeholder="Description en anglais" value={descriptionEn} onChange={setDescriptionEn} />
                                    </div>

                                    {/* <div className="sm:col-span-2 col-span-1">
                                        <Input label="Lien youtube" type="text" value={video} onChange={(e) => setVideo(e.target.value)} />
                                    </div> */}


                                    {/* <Input label="Zone produit" type="text" value={zoneProduct} onChange={(e) => setZoneProduct(e.target.value)} /> */}

                                    <SelectMulti
                                        isMulti
                                        options={listZones}
                                        onChange={handleChange}
                                        value={zoneProduct.map(value => ({ value: value, label: value }))}  // Map back to the object format required by react-select
                                    />

                                    <Input label="Lien youtube" type="text" value={video} onChange={(e) => setVideo(e.target.value)} />

                                </div>
                            </div>

                        </div>

                    </CardBody>
                    <CardFooter className="pt-0">
                        <Button variant="filled" className="bg-primary rounded-none shadow-none" onClick={() => addProductHandler()} fullWidth>
                            {selectedProduct ? "Modifier" : "Ajouter"}
                        </Button>

                    </CardFooter>
                </Card>
                ) : (
                    <div className="flex justify-center items-center p-5">
                        <Spinner />
                    </div>
                )}
            </Dialog >
        </>
    );
}
