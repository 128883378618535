import { Typography } from '@material-tailwind/react';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCartCheck } from "react-icons/bs";
import { useLocation, useNavigate } from 'react-router-dom';
import { CartContext } from '../../contexts/CartContext';
import { API_LINK } from '../../apiConfig';
import axios from 'axios';
import { useZone } from '../../contexts/ZoneContext';

function SuccessOrder() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { clearCart } = useContext(CartContext);
    const { zone } = useZone();

    const location = useLocation();
    const [order, setOrder] = useState(null);

    // Utility function to get query parameters
    const getQueryParam = (name) => {
        const urlParams = new URLSearchParams(location.search);
        return urlParams.get(name);
    };

    const orderId = getQueryParam('order_id');


    useEffect(() => {
        if (orderId && zone) {
            
            // Fetch order details using the order_id
            axios
                .patch(`${API_LINK}/orders/${orderId}/payment-status?zone=${zone}`)
                .then(({ data }) => {
                    setOrder(data);
                    setTimeout(() => {
                        navigate("/");
                        clearCart();
                    }, 3000);
                })
                .catch((error) => {
                    console.error('Error fetching order:', error);
                });
        }
    }, [orderId, zone]);


    // useEffect(() => {
    //     setTimeout(() => {
    //         navigate("/");
    //         clearCart();
    //     }, 3000);
    // }, [])


    return (
        <div className='w-full h-screen flex justify-center items-center'>
            <div className="grid place-items-center gap-4 p-16">
                <BsCartCheck size={100} className='text-primary' />
                <Typography variant="h4" className='text-primary w-2/3 text-center'>
                    {t('order-sent')}
                </Typography>
            </div>
        </div>
    )
}

export default SuccessOrder