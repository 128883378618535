import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { IoMdAdd, IoMdClose, IoMdRemove } from "react-icons/io";

import { CartContext } from "../contexts/CartContext";
import { API_LINK } from "../apiConfig";
import { SidebarContext } from "../contexts/SidebarContext";
import { useCurrency } from "../contexts/CurrencyContext";
import fx from 'money';
import { useZone } from "../contexts/ZoneContext";

const CartItem = ({ item }) => {

  const { currency, rates } = useCurrency();
  const { zone } = useZone();

  const { handleClose } = useContext(SidebarContext);
  const { removeFromCart, increaseAmount, decreaseAmount } = useContext(CartContext);

  // destructure item
  const { id, name, images, price, promotion, priceEuro, promotionEuro, amount, stockQuantity } = item;

  const increaseAmountWithStockVerify = (id) => {
    if (stockQuantity > amount) {
      increaseAmount(id);
    }
  }



  // Utility function to get the effective price
  // const getEffectivePrice = () => (promotion ? promotion : price);

  // Utility function to get the effective price depending on the zone
  const getEffectivePrice = () => {
    if (zone === "europe") {
      // If the zone is Europe, we use priceEuro and promotionEuro
      return promotionEuro ? promotionEuro : priceEuro;
    } else if (zone === 'middle-east') {
      // Else, we use the regular price and promotion
      return promotion ? promotion : price;
    }
  };

  // State for converted prices
  const [convertedPrice, setConvertedPrice] = useState(getEffectivePrice());
  const [convertedTotalPrice, setConvertedTotalPrice] = useState(getEffectivePrice() * amount);

  // fx.base = 'AED';

  const [loadingRates, setLoadingRates] = useState(true);

  // Set loading to false after rates are fetched
  useEffect(() => {
    if (Object.keys(rates).length > 0) {
      setLoadingRates(false);
    }
  }, [rates]);

  useEffect(() => {
    fx.base = zone === "europe" ? "EUR" : zone === "middle-east" && "AED";

    if (!loadingRates && Object.keys(rates).length > 0 && currency && rates[currency]) {
      if (Object.keys(rates).length > 0) {
        // Determine which price to convert
        const effectivePrice = getEffectivePrice(); // Use the effective price
        const totalPrice = effectivePrice * amount; // Calculate total price based on the effective price

        // Convert effective price
        const converted = fx.convert(effectivePrice, { from: fx.base, to: currency }).toFixed(2);
        setConvertedPrice(converted);

        // Convert total price
        const convertedTotal = fx.convert(totalPrice, { from: fx.base, to: currency }).toFixed(2);
        setConvertedTotalPrice(convertedTotal);
      } else {
        // If no rates, just set the default prices
        setConvertedPrice(getEffectivePrice().toFixed(2));
        setConvertedTotalPrice((getEffectivePrice() * amount).toFixed(2));
      }
    }
  }, [currency, rates, loadingRates, price, promotion, amount, zone]);


  return (
    <div className="flex gap-x-4 py-2 lg:px-6 border-b border-gray-200 w-full font-light text-gray-500">
      <div className="w-full min-h-[150px] flex items-center gap-x-4">
        {/* image */}
        <Link to={`/product/${id}`} onClick={handleClose}>
          <img className="max-w-[80px]" src={`${API_LINK}/${images[0]}`} alt={name} />
        </Link>
        <div className="w-full flex flex-col">
          {/* name and remove icon */}
          <div className="flex justify-between mb-2">
            {/* name */}
            <Link
              to={`/product/${id}`} onClick={handleClose}
              className="text-sm uppercase font-medium max-w-[240px] text-primary hover:underline"
            >
              {name}
            </Link>
            {/* remove icon */}
            <div
              onClick={() => removeFromCart(id)}
              className="text-xl cursor-pointer"
            >
              <IoMdClose className="text-gray-500 hover:text-red-500 transition" />
            </div>
          </div>
          <div className="flex gap-x-2 h-[36px] text-sm">
            {/* quantity */}
            <div className="flex flex-1 max-w-[100px] items-center h-full border text-primary font-medium">
              <div onClick={() => decreaseAmount(id)} className="h-full flex-1 flex justify-center items-center cursor-pointer">
                <IoMdRemove />
              </div>
              <div className="h-full flex justify-center items-center px-2">
                {amount}
              </div>
              <div onClick={() => increaseAmountWithStockVerify(id)} className="h-full flex flex-1 justify-center items-center cursor-pointer">
                <IoMdAdd />
              </div>
            </div>
            {/* item price */}
            <div className="flex flex-1 justify-around items-center">
              {`${convertedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ${currency}`}
            </div>
            {/* final price */}
            <div className="flex flex-1 justify-end items-center text-primary font-medium text-center">
              {`${convertedTotalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ${currency}`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
