import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, CardBody, CardFooter, Chip, IconButton, Option, Select, Tooltip, Typography } from '@material-tailwind/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { BsPencil, BsTrash, BsEye, BsFileEarmarkArrowDown } from 'react-icons/bs'

import { format } from 'date-fns'
import { fr } from 'date-fns/locale'

import DatePicker from '../../components/dashboard/DatePicker'
import * as XLSX from 'xlsx';

import { AuthContext } from '../../contexts/AuthContext'
import { API_LINK } from '../../apiConfig'
import axios from 'axios'
import { DeleteOrder } from '../../widgets/modals/orders/DeleteOrder'
import { ShowOrder } from '../../widgets/modals/orders/ShowOrder'
import { UpdateStateOrder } from '../../widgets/modals/orders/UpdateStateOrder'
import { PaymentOrder } from '../../widgets/modals/orders/PaymentOrder'



const TABLE_HEAD = ["N°", "Client", "Quantité", "Prix", "Date", "État", "Paiement"];




const Orders = () => {
  const { authTokens } = useContext(AuthContext);
  const [orders, setOrders] = useState([]);

  const [openShowOrder, setOpenShowOrder] = useState(false);
  const handleOpenShowOrder = () => setOpenShowOrder((cur) => !cur);

  const [openUpdateOrder, setOpenUpdateOrder] = useState(false);
  const handleOpenUpdateOrder = () => setOpenUpdateOrder((cur) => !cur);

  const [openUpdatePaymentOrder, setOpenUpdatePaymentOrder] = useState(false);
  const handleOpenUpdatePaymentOrder = () => setOpenUpdatePaymentOrder((cur) => !cur);

  const [openDeleteOrder, setOpenDeleteOrder] = useState(false);
  const handleOpenDeleteOrder = () => setOpenDeleteOrder((cur) => !cur);

  const [selectedOrder, setSelectedOrder] = useState();

  const [date, setDate] = useState();
  const [state, setState] = useState();
  const [zones, setZones] = useState([]);

  const [page, setPage] = useState(1);
  const perPage = 10;

  const [numberOfPages, setNumberOfPages] = useState(0);


  useEffect(() => {
    axios.get(`${API_LINK}/shippingprices/zones`, {
      headers: {
        Authorization: `Bearer ${authTokens?.accessToken}`
      }
    }).then(({ data }) => {
      setZones(data);      
    }).catch((error) => {
      console.log(error);
    })
  }, []);



  useEffect(() => {
    axios.get(`${API_LINK}/orders?page=${page}&perPage=${perPage}&date=${date}&state=${state}`, {
      headers: {
        Authorization: `Bearer ${authTokens?.accessToken}`
      }
    }).then(({ data }) => {
      setOrders(data.orders);      
      setNumberOfPages(data.totalPages);
    }).catch((error) => {
      console.log(error);
    })
  }, [page, date, state])

  const visiblePages = 10; // Number of visible page numbers

  const firstPage = Math.max(1, page - Math.floor(visiblePages / 2));
  const lastPage = Math.min(numberOfPages, firstPage + visiblePages - 1);

  const pageNumbers = Array.from({ length: lastPage - firstPage + 1 }, (_, i) => firstPage + i);


  const translateStatus = (status) => {
    const statusMap = {
      'pending': 'En attente',
      'processing': 'En traitement',
      'shipped': 'Expédiée',
      'delivered': 'Livrée',
      'canceled': 'Annulée'
    };
    return statusMap[status] || status; // Fallback to the original status if not found
  };

  const translateStatusPayment = (status) => {
    const statusMap = {
      'paid': 'Payée',
      'unpaid': 'Non payée',
    };
    return statusMap[status] || status; // Fallback to the original status if not found
  };


  const translateShippingType = (shippingType) => {
    return shippingType === 'economical' ? 'Économique' : 'Express';
  };


  // const deviseHandler = (zones, address) => {
  //   if (zones.length !== 0 && address) {
  //     const zone = zones.filter(z => address === z.country);

  //     if (zone[0].zone === 'europe') {
  //       return 'EUR';
  //     } else if (zone[0].zone === 'middle-east') {
  //       return 'AED';
  //     }
  //   }
  // }


  const deviseHandler = (zones, address) => {
    if (zones.length !== 0 && address) {   
      const zone = zones.filter(z => address === z.country);

      // Check if zone is empty before trying to access its properties
      if (zone.length > 0) {
        if (zone[0].zone === 'europe') {          
          return 'EUR';
        } else if (zone[0].zone === 'middle-east') {
          return 'AED';
        }
      } else {
        console.error(`No matching zone found for address: ${address}`);
        return null; // or a default value
      }
    }
    return null; // Return null if no zones or address is undefined
  }

  const exportToExcel = () => {
    axios.get(`${API_LINK}/orders/download?date=${date}&state=${state}`, {
      headers: {
        Authorization: `Bearer ${authTokens?.accessToken}`
      }
    }).then(({ data }) => {
      const rows = [];
      data.forEach(order => {
        const clientInfo = {
          'N° Commande': `N${String(order.id).padStart(5, '0')}`,
          'Nom du client': order.customerName,
          'Téléphone': order.customerPhone,
          'Adresse email': order.customerEmail,
          'Date commande': format(new Date(order.orderDate), 'dd MMMM yyyy', { locale: fr }),
          // 'Wilaya de livraison': order.shippingWilaya,
          'Ville': order.shippingCity,
          'Code postal': order.shippingZipCode,
          'Adresse de livraison': order.shippingAddress,
          'Type de livraison': translateShippingType(order.shippingType),
          'Etat': translateStatus(order.status),
        };
        order.orderDetails.forEach(detail => {
          rows.push({
            ...clientInfo,
            'Produit': detail.product.name,
            'Type': detail.product.type,
            'Spécification': detail.product.specification,
            'Prix': detail.unitPrice,
            'Quantité': detail.quantity,
            'Total': detail.unitPrice * detail.quantity
          });
        });
      });

      const worksheet = XLSX.utils.json_to_sheet(rows);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Commandes détaillées");
      XLSX.writeFile(workbook, "Commandes.xlsx");
    }).catch((error) => {
      console.log(error);
    })
  };

  return (
    <div className="mt-12">
      <Card shadow={false} className="h-full w-full bg-secondary rounded-none border border-gray-400">
        <CardBody className={`px-0 xl:overflow-hidden`} /* overflow-scroll  */>

          <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center p-4 text-primary">
            <div>
              <Typography variant="h4">
                Commandes récentes
              </Typography>
              <Typography className="mt-1">
                Ce sont des détails sur les dernières commandes
              </Typography>
            </div>
            <div className="flex w-full shrink-0 gap-2 md:w-max">

              <div className="sm:flex-1 sm:mr-6 sm:mb-0 mb-3">
                <Select label="État" onChange={(e) => setState(e)}>
                  <Option value='undefined'>Tous</Option>
                  <Option value='pending'>En attente</Option>
                  <Option value='processing'>En traitement</Option>
                  <Option value='validated'>Validée</Option>
                  <Option value='shipped'>Expédiée</Option>
                  <Option value='delivered'>Livrée</Option>
                  <Option value='canceled'>Annulée</Option>
                </Select>
              </div>

              <div className='sm:flex-1 sm:mr-6 sm:mb-0 mb-3'><DatePicker date={date} setDate={setDate} /></div>
            </div>
          </div>

          {orders.length !== 0 ?
            <div className={`${orders.length !== 0 && 'overflow-x-scroll'}`}>
              <table className="w-full min-w-max table-auto text-left">
                <thead>
                  <tr>
                    {TABLE_HEAD.map((head) => (
                      <th
                        key={head}
                        className="border-y border-white bg-primary p-4"
                      >
                        <Typography
                          variant="paragraph"
                          color="white"
                          className=" leading-none"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                    <th className="border-y border-white bg-primary p-4">
                      <Tooltip content="Télécharger" >
                        <IconButton variant="text" className='p-0' onClick={() => exportToExcel()}>
                          <BsFileEarmarkArrowDown className="h-5 w-5 text-white" />
                        </IconButton>
                      </Tooltip>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map(
                    (
                      {
                        id,
                        customerName,
                        shippingCountry,
                        orderDate,
                        status,
                        paymentStatus,
                        orderDetails
                      },
                      index,
                    ) => {
                      const isLast = index === orders.length - 1;
                      const classes = isLast
                        ? "p-4"
                        : "p-4 border-b border-blue-gray-50";

                      return (
                        <tr key={id} className="text-primary">
                          <td className={classes}>
                            {/* <div className="flex items-center gap-3">
                              <Typography
                                variant="paragraph"
                                color="blue-gray"
                                className="capitalize text-primary"
                              >
                                {`N${String(id).padStart(5, '0')}`}
                              </Typography>
                            </div> */}

                            <Tooltip className="" content={
                              <Typography
                                variant="small"
                                className=" capitalize"
                              >
                                {`N${String(id).padStart(5, '0')}`}
                              </Typography>}>
                              <Typography
                                variant="paragraph"
                                color="blue-gray"
                                className="capitalize text-primary"
                              >
                                {(`N${String(id).padStart(5, '0')}`).substring(0, 4)}.
                              </Typography>
                            </Tooltip>
                          </td>
                          <td className={classes}>
                            <Tooltip className="" content={
                              <Typography
                                variant="small"
                                className=" capitalize"
                              >
                                {customerName}
                              </Typography>}>
                              <Typography
                                variant="paragraph"
                                color="blue-gray"
                                className="capitalize text-primary"
                              >
                                {customerName.length > 14 ? customerName.substring(0, 12) + '...' : customerName}
                              </Typography>
                            </Tooltip>
                          </td>
                          <td className={classes}>
                            <div className="flex items-center gap-3">
                              <Typography
                                variant="paragraph"
                                color="blue-gray"
                                className="capitalize text-primary"
                              >
                                {orderDetails.map((orderDetail) => {
                                  return parseInt(orderDetail.quantity);
                                }).reduce((totalQuantity, currentQuantity) => totalQuantity + currentQuantity, 0)} produits
                              </Typography>
                            </div>
                          </td>
                          <td className={classes}>
                            <Tooltip className="" content={orderDetails.map((orderDetail) => <Typography
                              key={orderDetail.id}
                              variant="small"
                              className=" capitalize"
                            >
                              {orderDetail.unitPrice.toString()} * {orderDetail.quantity} {deviseHandler(zones, shippingCountry)}
                            </Typography>)}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="text-primary"
                              >
                                {orderDetails.map((orderDetail) => {
                                  return parseInt(orderDetail.unitPrice * orderDetail.quantity);
                                }).reduce((totalPrice, currentPrice) => totalPrice + currentPrice, 0)} {deviseHandler(zones, shippingCountry)}

                              </Typography>
                            </Tooltip>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="capitalize text-primary"
                            >
                              {format(new Date(orderDate), 'dd MMMM yyyy | hh:mm', { locale: fr })}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <div className="w-max bg-white rounded-md">
                              <Chip
                                size="sm"
                                variant="ghost"
                                value={status === 'pending' ? 'En attente' : status === 'processing' ? 'En traitement' : status === 'validated' ? "Validée" : status === 'shipped' ? 'Expédiée' : status === 'delivered' ? 'Livrée' : status === 'canceled' && 'Annulée'}
                                color={
                                  status === "delivered" ? "green" : status === "pending" ? "amber" : status === "processing" ? "purple" : status === "validated" ? "cyan" : status === "canceled" ? "red" : status === "shipped" && "blue"
                                }
                              />
                            </div>
                          </td>
                          <td className={classes}>
                            <div className="w-max bg-white rounded-md" onClick={() => { if (paymentStatus === "unpaid") { setSelectedOrder({ id, customerName, shippingCountry, orderDate, status, paymentStatus, orderDetails }); handleOpenUpdatePaymentOrder() } }}>
                              <Chip
                                size="sm"
                                variant="ghost"
                                value={translateStatusPayment(paymentStatus)}
                                color={
                                  paymentStatus === "paid" ? "green" : paymentStatus === "unpaid" && "red"
                                }
                              />
                            </div>
                          </td>
                          <td className={classes}>
                            <Tooltip content="Détail">
                              <IconButton variant="text" onClick={() => { setSelectedOrder(id); handleOpenShowOrder() }}>
                                <BsEye className="h-4 w-4 text-primary" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip content="Modifier">
                              <IconButton variant="text" onClick={() => { setSelectedOrder({ id, status }); handleOpenUpdateOrder() }}>
                                <BsPencil className="h-4 w-4 text-primary" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip content="Supprimer">
                              <IconButton variant="text" onClick={() => { setSelectedOrder(id); handleOpenDeleteOrder() }}>
                                <BsTrash className="h-4 w-4 text-primary" />
                              </IconButton>
                            </Tooltip>
                          </td>
                        </tr>
                      );
                    },
                  )}
                </tbody>
              </table>
            </div> :
            (
              <div className="w-full">
                <div className="flex flex-col justify-center items-center bg-primary text-secondary p-6 rounded-none mx-6">
                  <ExclamationTriangleIcon className='w-20 mb-4' />
                  <Typography variant="lead" className="">
                    {(date || state) ? "Vous n'avez pas une commande avec ce filtre" : "Vous n'avez encore eu de commandes !"}
                  </Typography>
                </div>
              </div>
            )}
        </CardBody>
        {orders.length !== 0 && <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Button disabled={page === 1} onClick={() => setPage(page - 1)} variant="outlined" size="sm" className="bg-primary text-white capitalize rounded-none">
            Précédent
          </Button>
          <div className="flex items-center gap-2">
            {pageNumbers.map((pageNum) => (
              <IconButton onClick={() => setPage(pageNum)} key={pageNum} variant={page === pageNum ? "outlined" : "text"} size="sm" className={`${page === pageNum && "border-primary"} text-primary rounded-none`}>
                {pageNum}
              </IconButton>
            ))}
          </div>
          <Button disabled={page === numberOfPages} onClick={() => setPage(page + 1)} variant="filled" size="sm" className="bg-primary text-white capitalize rounded-none">
            Suivant
          </Button>
        </CardFooter>}
      </Card>

      <ShowOrder open={openShowOrder} handleOpen={handleOpenShowOrder} setOrders={setOrders} selectedOrder={selectedOrder} setSelectedOrder={setSelectedOrder} />
      <UpdateStateOrder open={openUpdateOrder} handleOpen={handleOpenUpdateOrder} setOrders={setOrders} selectedOrder={selectedOrder} setSelectedOrder={setSelectedOrder} />
      <PaymentOrder open={openUpdatePaymentOrder} handleOpen={handleOpenUpdatePaymentOrder} setOrders={setOrders} selectedOrder={selectedOrder} setSelectedOrder={setSelectedOrder} zones={zones} />
      <DeleteOrder open={openDeleteOrder} handleOpen={handleOpenDeleteOrder} setOrders={setOrders} selectedOrder={selectedOrder} setSelectedOrder={setSelectedOrder} />
    </div>
  )
}

export default Orders