import React, { useContext, useEffect, useState } from "react";
import {
    Input,
    Button,
    Dialog,
    Card,
    CardBody,
    CardFooter,
    Typography,
    Spinner,
    Avatar,
    Chip,
} from "@material-tailwind/react";
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { API_LINK } from "../../../apiConfig";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import profile from "../../../img/profile.jpg";

const TABLE_HEAD = ["Produit", "Prix", "Quantité", "Total"];

export const ShowOrder = ({ open, handleOpen, selectedOrder, setOrders, setSelectedOrder }) => {
    const { authTokens } = useContext(AuthContext);
    const [order, setOrder] = useState();
    const [zones, setZones] = useState([]);

    const [loading, setLoading] = useState(false);
    // const [listWilayas, setListWilayas] = useState([]);
    const [listCountries, setListCountries] = useState([])

    const [editablePrices, setEditablePrices] = useState({});


    useEffect(() => {
        axios.get(`${API_LINK}/shippingprices/countries-by-zone/`).then(({ data }) => {
            setListCountries(data);
        }).catch((error) => {
            console.log(error);
        })
    }, [])

    useEffect(() => {
        axios.get(`${API_LINK}/shippingprices/zones`, {
          headers: {
            Authorization: `Bearer ${authTokens?.accessToken}`
          }
        }).then(({ data }) => {
          setZones(data);
        }).catch((error) => {
          console.log(error);
        })
      }, []);

    useEffect(() => {
        if (open && selectedOrder) {
            setLoading(true);
            axios.get(`${API_LINK}/orders/${selectedOrder}`, {
                headers: {
                    Authorization: `Bearer ${authTokens?.accessToken}`
                }
            }).then(({ data }) => {
                setOrder(data);
                const initialPrices = data.orderDetails.reduce((acc, item) => {
                    acc[item.id] = item.unitPrice;
                    return acc;
                }, {});
                setEditablePrices(initialPrices);
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [open, selectedOrder]);

    const closeModalHandler = () => {
        handleOpen();
        setSelectedOrder(null);
    };

    // const showDeliveryPrice = (wilaya, deliveryType) => {
    //     const selectedWilaya = listWilayas.find(item => item.destination === wilaya);
    //     if (selectedWilaya) {
    //         return deliveryType === 'economical' ? selectedWilaya.economical : selectedWilaya.express || 0;
    //     }
    //     return 0;
    // };

    const showDeliveryPrice = (country) => {
        const selectedCountry = listCountries.find(item => item.country === country);
        if (selectedCountry) {
            return selectedCountry.price
        } else {
            return 0;
        }
    };

    const deviseHandler = (zones, address) => {
        if (zones.length !== 0 && address) {
            const zone = zones.filter(z => address === z.country);

            if (zone[0].zone === 'europe') {
                return 'EUR';
            } else if (zone[0].zone === 'middle-east') {
                return 'AED';
            }
        }
    }


    const handlePriceChange = (id, value) => {
        setEditablePrices(prev => ({
            ...prev,
            [id]: value
        }));
    };

    const handleUpdatePrices = () => {
        const updatedOrderDetails = order?.orderDetails.map((detail) => ({
            ...detail,
            unitPrice: editablePrices[detail.id] || detail.unitPrice,
        }));

        axios.patch(`${API_LINK}/orders/update/${selectedOrder}`, {
            orderDetails: updatedOrderDetails,
        }, {
            headers: {
                Authorization: `Bearer ${authTokens?.accessToken}`,
            },
        })
            .then(({ data }) => {
                console.log('Prices updated successfully');
                setOrder(data);
                setOrders((orders) => orders.map(order => order.id === data.id ? data : order));
                closeModalHandler(); // Optionally close the modal
            })
            .catch((error) => {
                console.error('Error updating prices:', error);
            });
    };

    const calculateTotalPriceProducts = () => {
        let total = 0;
        order?.orderDetails.forEach((orderDetail) => {
            const itemPrice = editablePrices[orderDetail.id] || orderDetail.unitPrice;
            total += itemPrice * orderDetail.quantity;
        });
        return total;
    };

    return (
        <Dialog size="md" open={open} handler={closeModalHandler} className="bg-transparent shadow-none">
            {!loading ? (
                <Card className="mx-auto w-full">
                    <CardBody className="flex flex-col gap-4">
                        <Typography variant="h4" color="blue-gray">
                            Détail de la commande
                        </Typography>
                        {order && (
                            <div className="w-full flex flex-col">
                                <div className="grid md:grid-cols-2 grid-cols-1 gap-4 w-full">
                                    <div className="flex flex-row items-center">
                                        <Avatar src={profile} alt="avatar" size="xl" variant="rounded" className="p-0.5" />
                                        <div className="ml-4">
                                            <Typography variant="lead" color="blue-gray" className="capitalize">
                                                {order?.customerName}
                                            </Typography>
                                            <Typography variant="paragraph" color="blue-gray">
                                                {order?.customerPhone}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className="flex flex-col items-end justify-center">
                                        <Chip
                                            size="sm"
                                            variant="ghost"
                                            className="w-min mb-2"
                                            value={
                                                order?.status === 'pending' ? 'En attente' :
                                                    order?.status === 'processing' ? 'En traitement' :
                                                        order?.status === 'shipped' ? 'Expédiée' :
                                                            order?.status === 'delivered' ? 'Livrée' :
                                                                order?.status === 'canceled' && 'Annulée'
                                            }
                                            color={
                                                order?.status === "delivered" ? "green" :
                                                    order?.status === "pending" ? "amber" :
                                                        order?.status === "processing" ? "purple" :
                                                            order?.status === "canceled" ? "red" :
                                                                order?.status === "shipped" && "blue"
                                            }
                                        />
                                        <Typography>
                                            {format(new Date(order?.orderDate), 'dd MMMM yyyy', { locale: fr })}
                                        </Typography>
                                    </div>
                                </div>

                                <div className="flex flex-row items-center pt-4">
                                    <Typography variant="paragraph" color="blue-gray" className="capitalize font-medium">
                                        Livraison
                                    </Typography>
                                    <div className="w-full ml-4">
                                        <Typography variant="paragraph" color="blue-gray">
                                            {/* {order?.shippingWilaya} - {order?.shippingType === 'economical' ? "Économique" : "Express"} */}
                                            {order?.shippingCountry} - {order?.shippingCity} / {order?.shippingZipCode} - ({showDeliveryPrice(order?.shippingCountry)} {deviseHandler(zones,order?.shippingCountry)})
                                        </Typography>
                                    </div>
                                </div>

                                <div className="flex flex-row items-center pb-4">
                                    <Typography variant="paragraph" color="blue-gray" className="capitalize font-medium">
                                        Adresse
                                    </Typography>
                                    <div className="w-full ml-4">
                                        <Typography variant="paragraph" color="blue-gray">
                                            {order?.shippingAddress}
                                        </Typography>
                                    </div>
                                </div>

                                <div className="py-4 overflow-x-scroll overflow-y-scroll h-64">
                                    <table className="w-full min-w-max table-auto">
                                        <thead>
                                            <tr>
                                                {TABLE_HEAD.map((head) => (
                                                    <th key={head} className="border-y border-gray-400 p-4">
                                                        <Typography variant="paragraph" className="leading-none text-info font-medium">
                                                            {head}
                                                        </Typography>
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {order?.orderDetails.map((orderDetail) => {
                                                const classes = "p-4 border-b border-gray-400";
                                                return (
                                                    <tr key={orderDetail.id} className="text-primary">
                                                        <td className={classes}>
                                                            <div className="flex items-center gap-3">
                                                                <img src={`${API_LINK}/${orderDetail.product?.images[0]}`} alt={orderDetail.product?.name} className='w-16 h-16' />
                                                                <Typography
                                                                    variant="paragraph"
                                                                    color="blue-gray"
                                                                    className="capitalize"
                                                                >
                                                                    {orderDetail.product?.name}
                                                                </Typography>
                                                            </div>
                                                        </td>

                                                        <td className={classes}>
                                                            <div className="flex items-center gap-3">
                                                                {authTokens?.user.role === 'admin' && <Input
                                                                    type="number"
                                                                    value={editablePrices[orderDetail.id] || orderDetail.unitPrice}
                                                                    onChange={(e) => handlePriceChange(orderDetail.id, parseFloat(e.target.value))}
                                                                />}
                                                                <Typography
                                                                    variant="paragraph"
                                                                    color="blue-gray"
                                                                    className="capitalize"
                                                                >
                                                                    {editablePrices[orderDetail.id] || orderDetail.unitPrice} {deviseHandler(zones,order?.shippingCountry)}
                                                                </Typography>
                                                            </div>
                                                        </td>

                                                        <td className={classes}>
                                                            <div className="flex items-center gap-3">
                                                                <Typography
                                                                    variant="paragraph"
                                                                    color="blue-gray"
                                                                    className="capitalize font-semibold"
                                                                >
                                                                    {orderDetail?.quantity}
                                                                </Typography>
                                                            </div>
                                                        </td>

                                                        <td className={classes}>
                                                            <div className="flex items-center gap-3">
                                                                <Typography
                                                                    variant="paragraph"
                                                                    color="blue-gray"
                                                                    className="capitalize"
                                                                >
                                                                    {(
                                                                        (editablePrices[orderDetail.id] || orderDetail.unitPrice) * orderDetail.quantity
                                                                    ).toFixed(2)} {deviseHandler(zones,order?.shippingCountry)}
                                                                </Typography>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="flex justify-between border-t border-gray-400 pt-4">
                                    <Typography variant="paragraph" color="blue-gray" className="font-medium">
                                        Total
                                    </Typography>
                                    <Typography variant="paragraph" color="blue-gray" className="font-medium">
                                        {calculateTotalPriceProducts().toFixed(2)} {deviseHandler(zones,order?.shippingCountry)}
                                    </Typography>
                                </div>

                                <div className="flex justify-between pt-4">
                                    <Typography variant="paragraph" color="blue-gray" className="font-medium">
                                        Total avec livraison
                                    </Typography>
                                    <Typography variant="paragraph" color="blue-gray" className="font-medium">
                                        {(calculateTotalPriceProducts() + showDeliveryPrice(order?.shippingCountry)).toFixed(2)} {deviseHandler(zones,order?.shippingCountry)}
                                    </Typography>
                                </div>

                                {order?.PromoCode !== null && <div className="flex justify-between pt-4">
                                    <Typography variant="paragraph" color="blue-gray" className="font-medium">
                                        Total avec promotion ({order?.PromoCode.discountValue} {order?.PromoCode.discountType === "amount" ? deviseHandler(zones,order?.shippingCountry) : "%"}) sans livraison
                                    </Typography>
                                    <Typography variant="paragraph" color="blue-gray" className="font-medium">
                                        {(order?.PromoCode.discountType === 'percentage' ? ((100 - order?.PromoCode.discountValue) / 100) * (calculateTotalPriceProducts()) : order?.PromoCode.discountType === 'amount' && ((calculateTotalPriceProducts()) - order?.PromoCode.discountValue)).toFixed(2)} {deviseHandler(zones,order?.shippingCountry)}
                                    </Typography>
                                </div>}

                                {order?.PromoCode !== null && <div className="flex justify-between pt-4">
                                    <Typography variant="paragraph" color="blue-gray" className="font-medium">
                                        Total avec promotion ({order?.PromoCode.discountValue} {order?.PromoCode.discountType === "amount" ? deviseHandler(zones,order?.shippingCountry) : "%"}) + livraison
                                    </Typography>
                                    <Typography variant="paragraph" color="blue-gray" className="font-medium">
                                        {(order?.PromoCode.discountType === 'percentage' ? (((100 - order?.PromoCode.discountValue) / 100) * (calculateTotalPriceProducts()) + showDeliveryPrice(order?.shippingCountry)) : order?.PromoCode.discountType === 'amount' && ((calculateTotalPriceProducts() + showDeliveryPrice(order?.shippingCountry)) - order?.PromoCode.discountValue)).toFixed(2)} {deviseHandler(zones,order?.shippingCountry)}

                                        {/* {(calculateTotalPriceProducts() + showDeliveryPrice(order?.shippingWilaya, order?.shippingType)).toFixed(2)} $ */}
                                    </Typography>
                                </div>}

                            </div>
                        )}
                    </CardBody>
                    <CardFooter className="pt-0 flex justify-between gap-4">
                        <Button
                            variant="filled"
                            className="shadow-none bg-primary rounded-none"
                            onClick={closeModalHandler}
                            fullWidth
                        >
                            D'accord
                        </Button>
                        {authTokens?.user.role === 'admin' && <Button
                            variant="filled"
                            color="orange"
                            className="shadow-none rounded-none"
                            onClick={handleUpdatePrices}
                            fullWidth
                        >
                            Enregistrer les modifications
                        </Button>}
                    </CardFooter>
                </Card>
            ) : (
                <div className="flex justify-center items-center w-full h-full">
                    <Spinner />
                </div>
            )}
        </Dialog>
    );
};
